





































































































import { Component, Prop, Vue } from "vue-property-decorator";
import GA from "@/app/modules/_global/classes/Analytics";

import Schedule from "./Schedule.vue";

type Tile = {
  name: string;
  contentable: {
    title: string;
    mass_market: boolean;
    uploads: [];
  };
};

@Component({
  components: {
    Schedule
  }
})
export default class ResourcePage extends Vue {
  @Prop(Object) readonly tile!: Tile;

  carousel = 0;
  expanded = null;
  intersecting = false;

  constructor() {
    super();

    GA.addCustomDimension(1, `${this.userData?.user.id}`);
    GA.trackView(`Resource: ${this.tile.contentable.title}`);
  }

  get userData() {
    return this.$store.getters["profile/data"];
  }

  get newsData() {
    return this.$store.getters["news/data"];
  }

  get unavailable() {
    const massMarket = this.tile.contentable.mass_market;
    return (!massMarket && !this.hasRole("inhabitant")) || this.hasRole("guest");
  }

  get uploadsLength() {
    return this.tile.contentable.uploads?.length;
  }

  beforeDestroy() {
    this.scrollTop();
  }

  onIntersect(entries: IntersectionObserverEntry[]) {
    this.intersecting = entries[0].isIntersecting;
  }

  book() {
    this.$router.push({
      name: "booking.reservations.create"
    });
  }

  hasRole(role: string): boolean {
    return this.userData.roles.some(r => r.slug == role);
  }

  scrollTop() {
    const card = document.getElementById("card");
    if (card) card.scrollIntoView({ behavior: "smooth" });
  }
}
